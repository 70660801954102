import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'

export const CardsImages = ({ slice }) => {
  const isFeatured = slice.primary.full_length_card === true;

  return (
    <section className="CardsImages" id={slice.primary.anchor_id || ''}>
      <div className="Slices_Container Container">
        <div className={isFeatured ? 'Cards__text_featured' : 'Cards__text'}>
          {isFilled.richText(slice.primary.header.richText) && (
            <PrismicRichText field={slice.primary.header.richText} />
          )}
          {isFilled.richText(slice.primary.text.richText) && (
            <PrismicRichText field={slice.primary.text.richText} />
          )}
        </div>
        <div
          className={`CardsImages__Container ${
            isFeatured ? 'featured_container' : ''
          }`}
        >
          {slice.items.map((item, index) => {
            return (
              <div
                key={index}
                className={isFeatured ? 'card_featured' : 'card'}
              >
                <div className={isFeatured ? 'image-basis' : ''}>
                  {isFilled.image(item.image) && (
                    <GatsbyImage
                      image={item.image.gatsbyImageData}
                      alt={item.image.alt || ''}
                      className="card_image"
                    />
                  )}
                </div>
                <div
                  className={`card_text ${isFeatured ? 'card-basis' : ''}`}
                >
                  {isFilled.richText(item.header.richText) && (
                    <PrismicRichText field={item.header.richText} />
                  )}
                  {isFilled.richText(item.text.richText) && (
                    <PrismicRichText field={item.text.richText} />
                  )}
                  <div className="btn-container">
                    {isFilled.keyText(item.primary_button_label) &&
                      isFilled.link(item.primary_button_link) && (
                        <PrismicLink
                          href={item.primary_button_link.url}
                          key={item.primary_button_label}
                          className="tertiary-btn"
                        >
                          {item.primary_button_label}
                        </PrismicLink>
                      )}
                    {isFilled.keyText(item.secondary_button_label) &&
                      isFilled.link(item.secondary_button_link) && (
                        <PrismicLink
                          href={item.secondary_button_link.url}
                          key={item.secondary_button_label}
                          className="tertiary-btn"
                        >
                          {item.primary_button_label}
                        </PrismicLink>
                      )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyCardsImages on PrismicUpdatedPageDataBodyCardsImages {
    id
    primary {
      full_length_card
      anchor_id
      header {
        richText
      }
      text {
        richText
      }
    }
    items {
      image {
        gatsbyImageData
        url
        alt
      }
      header {
        richText
      }
      text {
        richText
      }
      primary_button_label
      primary_button_link {
        url
      }
      secondary_button_label
      secondary_button_link {
        url
      }
    }
  }
`
